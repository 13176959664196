// Library Import
import React, { useState } from 'react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { AppBar, Box, Toolbar } from '@mui/material'

// File Import
import CustomLink from '../../custom-components/CustomLink'
import useResponsive from '../../hooks/responsive'
import { useAuth } from '@/api/api-hooks/auth'
import GoToDirectory from '../../custom-components/GoToDirectory'
import Helpers from '../../helpers'
import headerStyles from '~/styles/scss/header/Header.module.scss'
import TailwindContainer from '../../custom-components/containers/TailwindContainer'
import InterFontWrapper from '@/lib/InterFontWrapper'
import RenderHeaderMenu from '@/components/layout/includes/RenderHeaderMenu'
import MobileMenus from '@/components/layout/includes/mobile-menu/MobileMenus'
import PTMLogo from '~/public/assets/images/logos/paytome.svg'

const dashboardRedirect = process?.env?.NEXT_PUBLIC_MY_ACCOUNT_URL || ''

const Header = () => {
  const [anchorElNav, setAnchorElNav] = useState(null)

  const [activeIndex, setActiveIndex] = React.useState(0)

  const { authenticated, logout } = useAuth()
  const { allMobileTab, tabLandscape } = useResponsive()
  const router = useRouter()

  // const currentRouter = router?.asPath

  const handleOpenNavMenu = event => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  // const redirectToPage = url => {
  //   setAnchorElNav(null)
  //   router.push(url)
  // }

  // const trigger = useScrollTrigger({
  //   disableHysteresis: true,
  //   threshold: 90
  // })

  const dashboardLink = e => {
    e.preventDefault()
    Helpers.redirectToDashboard()
  }

  const processSignOut = e => {
    e.preventDefault()
    logout.mutateAsync({}).then(res => {
      if (res.statusCode === 202) {
        GoToDirectory(`/login`)
      }
    })
  }

  return (
    <AppBar
      position='sticky'
      color='inherit'
      className={headerStyles['headerWr_nav'] + ' ' + headerStyles[`header-home-wr`]}
    >
      <div className={`${headerStyles[`headerWr`]}`}>
        <div className={headerStyles['header_sticky_style'] + ' relative z-[9999]'}>
          <TailwindContainer className={'relative'}>
            <Toolbar
              disableGutters
              className={headerStyles['headerMinHeight']}
              sx={{ justifyContent: tabLandscape ? 'space-between' : 'start' }}
            >
              <Box className={headerStyles[`logo-area-wr`]} onMouseEnter={() => setActiveIndex(0)}>
                <CustomLink href={'/'}>
                  <div className={'aspect-h-4 aspect-w-16 w-[172px] overflow-hidden lg:w-[200px]'}>
                    <Image
                      priority
                      quality={100}
                      // src={`/assets/images/common/logo-regular.png`}
                      // src={`/assets/images/logos/paytome.png`}
                      src={PTMLogo}
                      alt={'PayToMe.co'}
                      className={'h-full w-full object-cover object-center'}
                      fill={true}
                      // width={allMobileTab ? 136 : 192}
                      // height={allMobileTab ? 41 : 48}
                    />
                  </div>
                </CustomLink>
              </Box>

              <div
                className={'ml-auto hidden items-center justify-end gap-0 lg:min-w-[500px] xl:!flex 2xl:min-w-[840px]'}
                // sx={{ flexGrow: 1, display: { xs: 'none', lg: 'flex' } }}
                // className={headerStyles['headerWr_pages']}
              >
                <RenderHeaderMenu activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
              </div>

              <div className={'flex justify-start gap-4'}>
                <div className={'hidden xl:!block'}>
                  <div onMouseEnter={() => setActiveIndex(0)}>
                    {authenticated ? (
                      <div className={headerStyles['sign_area']}>
                        <div className={headerStyles['singIn']}>
                          <a href={`/logout`} className='ptm-custom-link' onClick={e => processSignOut(e)}>
                            Sign Out
                          </a>
                        </div>
                        <div className={headerStyles['singUp']}>
                          <a
                            href={`/dashboard`}
                            onClick={e => dashboardLink(e)}
                            className={`ptm-custom-link btn-ptm-default ${headerStyles['singUp_btn-ptm-default']}`}
                          >
                            Dashboard
                          </a>
                        </div>
                      </div>
                    ) : (
                      <div className={headerStyles['sign_area']}>
                        <div className={headerStyles['singIn']}>
                          <CustomLink href='/login'>Sign In</CustomLink>
                        </div>
                        <div className={headerStyles['singUp']}>
                          <CustomLink
                            href={'/pricing'}
                            className={`btn-ptm-default ${headerStyles['singUp_btn-ptm-default']}`}
                          >
                            Get Started
                          </CustomLink>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div>
                  <MobileMenus
                    dashboardRedirect={dashboardRedirect}
                    authenticated={authenticated}
                    processSignOut={processSignOut}
                  />
                </div>
              </div>
            </Toolbar>
          </TailwindContainer>
        </div>
      </div>
    </AppBar>
  )
}

export default Header
