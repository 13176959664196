import React from 'react'
import footerStyles from '~/styles/scss/footer/Footer.module.scss'
import Image from 'next/image'
import CustomLink from '@/custom-components/CustomLink'
import IconLists from '@/helpers/IconLists'
import PTMLogo from '~/public/assets/images/logos/paytome.svg'

const FooterSocial = () => {
  // const { desktopUp, desktopMini } = useResponsive()

  return (
    <>
      <div className={'grid grid-cols-1 gap-y-8 pb-8 md:grid-cols-10 lg:grid-cols-10 lg:gap-0'}>
        <div className={'col-span-2 flex items-center justify-center md:col-span-7 md:justify-start lg:col-span-8'}>
          <div
            className={
              'flex flex-col items-center justify-start gap-y-4 md:flex-row md:items-end md:gap-x-6 lg:gap-x-8 xl:gap-x-[38px]'
            }
          >
            <CustomLink href={'/'}>
              <div className={'aspect-h-4 aspect-w-16 w-[200px] overflow-hidden lg:w-[230px]'}>
                <Image
                  src={PTMLogo}
                  alt={'PayToMe.co'}
                  className={'h-full w-full object-cover object-center'}
                  fill={true}
                  // width={desktopUp ? 284 : 278}
                  // height={desktopUp ? 69 : 69}
                />
              </div>
            </CustomLink>
            <p className={'text-base font-semibold text-[#252525] xl:text-xl'}>PayToMe.co ®, 2024</p>
          </div>
        </div>
        <div className={'col-span-2 flex items-center justify-center md:col-span-3 lg:col-span-2'}>
          <div className={'flex w-full items-center justify-center gap-4 lg:justify-center'}>
            <CustomLink
              openNewTab
              href={`https://www.linkedin.com/company/paytome`}
              className={footerStyles['button_style']}
            >
              {IconLists.linkedinIcon}
            </CustomLink>
            {/*<CustomLink href={`#`} className={footerStyles['button_style']}>
                {IconLists.twitterIcon}
              </CustomLink>*/}
            <CustomLink
              openNewTab
              href={`https://www.youtube.com/@paytome-co`}
              className={footerStyles['button_style']}
            >
              {IconLists.youtubeIcon}
            </CustomLink>
            {/*<CustomLink href={`#`} className={footerStyles['button_style']}>
                {IconLists.instagramIcon}
              </CustomLink>
              <CustomLink href={`#`} className={footerStyles['button_style']}>
                {IconLists.facebookIcon}
              </CustomLink>*/}
          </div>
        </div>
      </div>
    </>
  )
}

export default FooterSocial
