import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  fetchAccountInfo,
  forgotPassword,
  loginToTheApp,
  logoutFromTheApp,
  registerToApp,
  resetPassword,
  verifyOTPLoginToTheApp
} from '../customerApi'
import { useEffect, useState } from 'react'
import secureLocalStorage from 'react-secure-storage'
import { getCookie, removeCookies, setCookie } from '@/utils/helpers/cookie'

export const useAuth = () => {
  const [authenticated, setAuthenticated] = useState(false)

  // const accessToken = typeof window !== 'undefined' ? secureLocalStorage.getItem('ptm_token') : false
  const accessToken = getCookie('auth_token')

  const cache = useQueryClient()
  const twentyFourHoursInMs = 1000 * 60 * 60 * 24

  const user = useQuery({
    enabled: accessToken !== undefined,
    queryKey: ['user'],
    queryFn: fetchAccountInfo,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: twentyFourHoursInMs,
    onSuccess: res => {
      // console.log({res})
      if (res?.statusCode === 401 || !res?.data?.data?.status) {
        setAuthenticated(false)
        removeCookies(['auth_token'])
        secureLocalStorage.removeItem('ptm_token')
        secureLocalStorage.removeItem('isAuthenticate')
      } else if (res?.statusCode === 200 && res?.data?.data?.status) {
        setAuthenticated(true)
      }
    }
  })

  useEffect(() => {
    // console.log({FFF:user?.data})
    if (user && user?.data?.statusCode === 200 && !!user?.data?.data?.status) {
      setAuthenticated(true)
    } else {
      setAuthenticated(false)
    }
  }, [user?.data?.statusCode])

  const login = useMutation({
    mutationKey: ['loginSubmit'],
    mutationFn: loginToTheApp,
    onSuccess: res => {
      if (res.statusCode === 301 && res.data.status) {
        if (res.data.token) {
          setCookie('auth_token', res.data.token)
        }
      } else {
        removeCookies(['auth_token'])
        secureLocalStorage.removeItem('ptm_token')
        secureLocalStorage.removeItem('isAuthenticate')
      }
    }
  })

  const verifyOTPLogin = useMutation({
    mutationKey: 'verifyOTPLogin',
    mutationFn: verifyOTPLoginToTheApp,
    onSuccess: res => {
      if (res.statusCode === 200 && res.data.status) {
        if (res.data.token) {
          setCookie('auth_token', res.data.token)
        }
        secureLocalStorage.setItem('isAuthenticate', 'true')
      } else {
        removeCookies(['auth_token'])
        secureLocalStorage.removeItem('isAuthenticate')
      }
    }
  })

  const logout = useMutation({
    mutationKey: ['logoutSubmit'],
    mutationFn: logoutFromTheApp,
    onSuccess: res => {
      if (res.statusCode === 202) {
        removeCookies(['auth_token'])
        secureLocalStorage.removeItem('ptm_token')
        secureLocalStorage.removeItem('isAuthenticate')
      }
    }
  })

  const register = useMutation({
    mutationKey: ['registerSubmit'],
    mutationFn: registerToApp,
    onSuccess: res => {
      if (res.statusCode === 301 && res.data.status) {
        secureLocalStorage.setItem('ptm_token', res.data.token)
      } else {
        removeCookies(['auth_token'])
        secureLocalStorage.removeItem('ptm_token')
        secureLocalStorage.removeItem('isAuthenticate')
      }
    }
  })

  const forgot = useMutation({
    mutationKey: ['forgotPassword'],
    mutationFn: forgotPassword
  })

  const reset = useMutation({
    mutationKey: ['resetPassword'],
    mutationFn: resetPassword
  })

  return {
    authenticated,
    user,
    login,
    verifyOTPLogin,
    logout,
    register,
    forgot,
    reset
  }
}
