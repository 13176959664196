// Library Import
import React from 'react'
// import { Grid } from '@mui/material'

// File Import
import FooterSocialMobile from './includes/FooterSocial'
import FooterActiveLink from './includes/FooterActiveLink'
import footerStyles from '/styles/scss/footer/Footer.module.scss'
import { footerMenuData } from '@/constants/footer/constant'
import InterFontWrapper from '@/lib/InterFontWrapper'
import { cn } from '@/lib/utils'

const Footer = () => {
  return (
    <footer className={footerStyles['ptm-footer-wr']}>
      <InterFontWrapper>
        <section className={footerStyles['ptm-footer-wr_details-and-copyright-section']}>
          <div className={'container'}>
            <div className={'pb-[48px] pt-[32px] lg:pb-[48px] lg:pt-[48px] xl:pb-[90px] xl:pt-[68px]'}>
              <div
                className={cn(
                  '!m-0 grid !grid-cols-1 gap-6 !p-0 md:!grid-cols-2 lg:!grid-cols-3 xl:!grid-cols-5 xs:grid-cols-5',
                  footerStyles['footer-details-section-wr']
                )}
                // container
                // justifyContent='space-center'
                // className={footerStyles['footer-details-section-wr']}
                // columnSpacing={{ xs: 0, sm: 0, md: 7.5 }}
                // rowSpacing={{ xs: 7.3, sm: 7.3, md: 0 }}
              >
                {footerMenuData?.length > 0 &&
                  footerMenuData.map((menu, index) => (
                    <div key={menu?.id} className={cn('col-span-1')}>
                      <div>
                        <h3>{menu?.title}</h3>
                        <ul>
                          {menu?.children?.map((item, idx) => (
                            <li key={idx}>
                              <FooterActiveLink href={item?.url}>{item?.label}</FooterActiveLink>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <FooterSocialMobile />
          </div>
        </section>
        <section className={footerStyles['ptm-footer-wr_disclaimer-section']}>
          <div
            className={
              'lg:px-auto flex items-center justify-center bg-[#38366d] px-6 pb-[36px] pt-[36px] text-white md:px-[52px] md:py-[30px] lg:py-[20px] xl:px-0 xl:py-[20px]'
            }
          >
            <div className={'container p-0'}>
              <div className={`flex flex-col items-start justify-center`}>
                <p className={'mb-2 !text-left text-lg font-semibold md:text-xl'}>
                  PayToMe.co (Pay To Me Inc.) is a financial technology company and is{' '}
                  <span className={'font-extrabold underline'}>not a bank</span>, Banking services provide by our
                  sponsor banks.
                </p>
                <p className={'!text-left text-lg font-semibold md:text-xl'}>
                  PayToMe.co (Pay To Me Inc.) is a registered ISO/MSP of Elavon Inc. (
                  <a href={`https://www.elavon.com`} className={'font-extrabold !underline'} target={'_blank'}>
                    www.elavon.com
                  </a>
                  )
                </p>
                {/*<p>&#169; {new Date().getFullYear()} PayToMe.co. Registered Payment Facilitator. All Rights Reserved</p>*/}
              </div>
            </div>
          </div>
        </section>
      </InterFontWrapper>
    </footer>
  )
}

export default Footer
