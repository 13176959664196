/**
 * Author: Hridoy Ahmed
 * Email: learnwithhridoy@gmail.com
 * Updated Date: 30 October 2022
 **/
import { getCookie } from '@/utils/helpers/cookie'

export const sendRequest = async (method = 'GET', url = '', postData = {}, queryparams = null, headers = {}) => {
  /* Get Token from local storage */
  // const token = secureLocalStorage.getItem('ptm_token')
  const token = getCookie('auth_token')

  /** If url contains parameter like https://hridoy.com?currentPage=1&showPerPage=10&search=javascript*/
  if (queryparams != null) {
    url += queryparams
  }

  /** If the method is GET & DELETE. Because GET & DELETE Method doesn't contain body in request headers */
  if (method === 'GET' || method === 'DELETE') {
    try {
      const result = await fetch(url, {
        method: method,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
          ...headers
        },
        credentials: 'include',
        referrerPolicy: 'origin',
        redirect: 'follow',
        mode: 'cors'
      })
      let data = await result.json()
      let statusCode = result.status

      return {
        data,
        statusCode
      }
    } catch (error) {
      console.warn(`Root API Error: ${error}`)

      return false
    }
  }

  /** Do for all Methods except GET & DELETE like PUT, POST */
  try {
    const result = await fetch(url, {
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        ...headers
      },
      credentials: 'include',
      referrerPolicy: 'origin',
      body: JSON.stringify(postData),
      redirect: 'follow',
      mode: 'cors'
    })
    let data = await result.json()
    let statusCode = result.status

    return {
      data,
      statusCode
    }
  } catch (error) {
    console.warn(`Root API Error: ${error}`)

    return false
  }
}

export const sendFileRequest = async (method = 'POST', url = '', payload = {}, headers = {}) => {
  /* Get Token from local storage */
  // const encryptedToken = secureLocalStorage.getItem(authConfig.storageTokenKeyName)
  // const token = Helpers.decryptValue(encryptedToken)

  try {
    const result = await fetch(url, {
      method: method,
      body: payload,
      headers: {
        Accept: 'application/json',
        contentType: 'application/x-www-form-urlencoded',
        // Authorization: 'Bearer ' + token,
        ...headers
      },
      credentials: 'include',
      referrerPolicy: 'origin',
      redirect: 'follow',
      mode: 'cors'
    })

    return await result.json()
  } catch (error) {
    console.warn(`Root API Error: ${error}`)

    return {
      data: null,
      statusCode: 0
    }
  }
}
