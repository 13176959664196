import { MainMenus } from '@/components/layout/includes/menu.constant'

export const footerMenuData = [
  {
    id: 1,
    title: 'Products',
    children: MainMenus[0].children,
    items: [
      {
        name: 'Digital Invoice',
        slug: '/online-invoice-generator'
        // slug: '/digital-invoice'
      },
      {
        name: 'Text-To-Pay',
        slug: '/text-to-pay'
      },
      {
        name: 'Digital Checkbook',
        slug: '/digital-checkbook'
      },
      {
        name: 'Retails POS System',
        slug: '/retails-pos-system'
      },
      {
        name: 'Online Checkout Options',
        slug: '/online-checkout-options'
      },
      {
        name: 'Digital Invoice Generator',
        slug: '/invoice-generator'
      }
    ]
  },
  {
    id: 2,
    title: 'Solutions Platforms',
    children: MainMenus[1].children,
    items: [
      {
        name: 'Merchant Services',
        slug: '/merchant-services'
      },
      // {
      //   name: 'Retail Payment Services',
      //   slug: '/retail-payment-services'
      // },
      {
        name: 'Accounts Receivables',
        slug: '/accounts-receivables'
      },
      {
        name: 'Accounts Payable',
        slug: '/accounts-payable'
      },
      {
        name: 'Expense Management',
        slug: '/expense-management'
      }
    ]
  },
  {
    id: 3,
    title: 'Merchant Services',
    children: MainMenus[2].children,
    items: [
      {
        name: 'PayFac (PayFac-as a-Service)',
        slug: '/payfac-as-a-service'
      },
      {
        name: 'PaaS (Payment-as-a-Service)',
        slug: '/payment-as-a-service'
      },
      {
        name: 'SaaS (Software-as-a-Service)',
        slug: '/software-as-a-service'
      }
    ]
  },
  {
    id: 4,
    title: 'Pricing',
    children: MainMenus[4].children,
    items: [
      {
        name: 'About us',
        slug: '/about-us'
      },
      {
        name: 'Contact Us',
        slug: '/contact-us'
      },
      {
        name: 'News & Press',
        slug: '/news'
      },
      {
        name: 'Board of Directors',
        slug: '/board-of-directors'
      },
      {
        name: 'Privacy Policy',
        slug: '/privacy-policy'
      },
      {
        name: 'Terms of Use',
        slug: '/terms-of-use'
      }
    ]
  },
  {
    id: 5,
    title: 'Company',
    children: MainMenus[3].children,
    items: [
      {
        name: 'About us',
        slug: '/about-us'
      },
      {
        name: 'Contact Us',
        slug: '/contact-us'
      },
      {
        name: 'News & Press',
        slug: '/news'
      },
      {
        name: 'Board of Directors',
        slug: '/board-of-directors'
      },
      {
        name: 'Privacy Policy',
        slug: '/privacy-policy'
      },
      {
        name: 'Terms of Use',
        slug: '/terms-of-use'
      }
    ]
  }
]
