import React from 'react'
import Link from 'next/link'
import { MainMenus } from '@/components/layout/includes/menu.constant'
import parse from 'html-react-parser'
import Image from 'next/image'
import iconList from '@/constants/IconList'
import ArrowUp from '~/public/assets/images/top-menu/ArrowUp.svg'
import { cn } from '@/utils/tsx-helpers'

const RenderHeaderMenu = ({ activeIndex, setActiveIndex }) => {
  // const currentRouter = router?.asPath

  return (
    <>
      {MainMenus.map((parent, index1) => (
        <>
          <div
            key={parent.id || index1}
            className={'fixed bottom-0 left-0 right-0 top-[0] z-[100] bg-transparent'}
            onClick={() => setActiveIndex(0)}
            onMouseEnter={() => setActiveIndex(0)}
            style={{
              display: !!activeIndex ? 'block' : 'none'
            }}
          />
          <div
            // key={index1}
            // href={parent.url}
            className={'relative z-[110] mt-[20px]'}
          >
            <p
              onMouseEnter={() => setActiveIndex(parent.id)}
              onClick={() => setActiveIndex(parent.id)}
              className={
                'relative block cursor-pointer rounded-none px-[12px] pb-[30px] pt-2.5 text-lg font-semibold text-[#0a225f] hover:text-[#100aff] xl:px-6 2xl:px-[44px]'
              }
              style={{
                color: activeIndex === parent.id ? '#100aff' : '#0a225f'
              }}
            >
              {parent.name}
              {parent?.children?.length > 0 && activeIndex === parent.id && (
                <span className={'absolute bottom-[-14px] left-0 right-0 flex justify-center'}>
                  <Image priority src={ArrowUp} alt={'arrow-up'} />
                </span>
              )}
            </p>
          </div>

          {parent?.children?.length > 0 && (
            <div
              style={{
                display: activeIndex === parent.id ? 'block' : 'none'
              }}
              className={cn(
                'absolute left-0 right-0 top-[90px] z-[9999] mx-auto min-h-[150px] max-w-fit rounded-[15px] bg-white shadow-2xl',
                parent?.wrapperClass
              )}
            >
              <div className={'flex items-stretch justify-start gap-0'}>
                <div className='relative my-5 w-1/5 px-5'>
                  <p className={'mb-[18px] mt-[20px] text-2xl font-bold'}>{parent?.label || ''}</p>
                  <p className={'text-base text-[#6F6C90]'}>{parent?.content || ''}</p>
                  <div className={`absolute left-auto right-2.5 top-0 h-full border-r border-[#A6A6A6]`} />
                </div>
                <div className={cn('m-5 grid w-4/5 grid-cols-3 gap-0', parent?.childGridClass)}>
                  {parent?.children.map((child, index2) => (
                    <div className={'group'} key={child?.id || index2} onClick={() => setActiveIndex(0)}>
                      <Link href={child.url}>
                        <div className={'flex h-[104px] justify-start gap-[18px]'}>
                          {!!child?.icon && (
                            <div className={'w-[72px] text-center'}>
                              <Image src={child.icon} width={72} height={72} alt={child.label} />
                            </div>
                          )}
                          <div className={'h-[72px] w-[calc(100%-72px)]'}>
                            <div className='flex items-center justify-start gap-1.5'>
                              <p className={'mb-0 text-lg font-bold text-[#170F49] group-hover:text-[#4A3AFF]'}>
                                {child.label}
                              </p>
                              <span className={'mt-[-3px] hidden group-hover:inline'}>{iconList.rightArrow}</span>
                            </div>
                            {!!child?.content && (
                              <div className={'text-base text-[#6F6C90]'}>{parse(child.content)}</div>
                            )}
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </>
      ))}
    </>
  )
}

export default RenderHeaderMenu
